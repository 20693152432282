import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const SquareLink = ({ title, link, image }) => {
  return (
    <div className="square-link">
      {image != null ? (
        <Img
          className="square-link-img"
          fluid={image.localFile.childImageSharp.fluid}
          style={{ position: "relative" }}
        />
      ) : null}
      <Link to={link} className="square-link-gradient">
        <div className="square-link-title">
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </Link>
    </div>
  )
}

export default SquareLink
